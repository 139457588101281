
$header-max-width: 1800px;
.site-header {
  background: $top-nav-background-color;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid $header-border-bottom;
  &.open {
    background: $top-nav-open-background-color;
  }
  .top-nav {
    // max-width: $header-max-width;
    width: 100%;
    min-height: 96px;
    max-height: 96px;
    display: flex;
    justify-content: space-between;
    .nav-container, .buttons-container, .logo-container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 $spacer / 2;
    }
    .logo-container {
      padding-left: $spacer * 1.5;
    }
    .nav-container {
      padding-left: $spacer * 1.5;
      order: 2;
      flex: 1 1 100%;
    }
    .buttons-container {
      padding-right: $spacer * 1.5;
      order: 3;
    }
    .logo {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        background: url('../img/logo_white.svg') no-repeat left;
        background-size: contain;
        width: 140px;
        height: 48px;
        display: block;
        // position: absolute;
      }
    }
    .nav-links {
      display: flex;
      margin: 0;
      height: 100%;
      list-style: none;
      // margin-left: $spacer * 2;
      li {
        display: inline-block;
        padding: 16px 0;
        margin: 0 16px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-top: 3px solid transparent;
        > a {
          color: $top-nav-link-color;
          text-transform: uppercase;
          font-family: $font-family-main;
          font-size: 14px;
          line-height: 1;
          letter-spacing: 0.2px;
          font-weight: 600;
          transition: 0.2s ease-in-out;
          &:hover {
            text-decoration: none;
            color: $top-nav-active-border;
          }
        }
        &.active {
          border-top: 3px solid $top-nav-active-border;
          a {
            color: $top-nav-active-border;
          }
        }
      }
    }
  .btn-outline-light {
    border: 1px solid rgba($top-nav-outline-button-outline, 0.5);
    color: $top-nav-outline-button-color;
    &:hover {
      background-color: transparent;
      border: 1px solid $top-nav-outline-button-outline-hover;
      color: $top-nav-outline-button-color-hover;
    }
  }    
  }
  @include media-breakpoint-down(lg) {
    .top-nav {
      flex-wrap: wrap;
      max-height: 140px;
      padding-bottom: 0;
      padding-top: $spacer;
      .nav-container {
        padding-left: $spacer;
        order: 3;
        width: 100%;
        .nav-links {
          padding-top: $spacer / 2;
          flex: auto;
          justify-content: flex-start;
          li {
            border-top: none;
            border-bottom: 3px solid transparent;
            &.active {
              border-top: none;
              border-bottom: 3px solid $top-nav-active-border;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }      
      }
      .buttons-container {
        order: 2;
        padding-right: $spacer;
      }
      .logo-container {
        order: 1;
        padding-left: $spacer;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .top-nav {
      .nav-links {
        li:first-child {
          margin-left: 0;
        }
      }
      .logo-container {
        padding-left: $spacer;
      }
      .buttons-container {
        padding-right: $spacer;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .top-nav {
      min-height: 72px;
      padding: $spacer / 2 0;
      .nav-container {
        padding-left: $spacer;
        display: none;
      }
      .logo-container {
        padding-left: 16px;
      }
      .buttons-container {
        padding-right: 16px;
      }
    }
    .logo {
      &::before {
        height: 42px !important;
      }
    }
  }
  .sublinks {
    height: 56px;
    width: 100%;
    background: $bottom-nav-background-color;
    .nav-links {
      display: flex;
      list-style: none;
      height: 100%;
      align-items: center;
      padding-left: $spacer;
      // max-width: $header-max-width;
      margin-left: auto;
      margin-right: auto;
      li {
        padding: $spacer / 2 0;
        margin: 0 $spacer;
        border-bottom: 3px solid transparent;
        height: 100%;
        display: flex;
        align-items: center;
        a {
          color: $bottom-nav-link-color;
          font-size: 17px;
          font-weight: 400;
          margin-top: 4px;
          line-height: 1.2;
          &:hover {
            text-decoration: none;
            color: $bottom-nav-link-hover-color;
          }
        }
        &.active {
          border-bottom: 3px solid $bottom-nav-active-border;
          a {
            font-weight: 600;
            color: $bottom-nav-link-hover-color;
          }
        }
        &:first-child {
          margin-left: $spacer / 2;
        }
        @include media-breakpoint-down(lg) {
          margin-left: 16px;
          margin-right: 16px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.header-dropdown {
  button {
    color: $top-nav-link-color;
    &:hover {
      color: $top-nav-link-color;
    }
  }
}
.burger-icon-container {
  background-color: $nav-burger-background;
  border: $nav-burger-border;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  &.open {
    border: none;
    background-color: $nav-burger-open-background;
  }
}
.burger-icon {
  width: 16px;
  height: 15px;
  position: relative;
  margin: auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.burger-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $nav-burger-color;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger-icon span:nth-child(1) {
  top: 0px;
}

.burger-icon span:nth-child(2),.burger-icon span:nth-child(3) {
  top: 6px;
}

.burger-icon span:nth-child(4) {
  top: 12px;
}
.burger-icon.open span {
  background: $nav-burger-open-color;
}
.burger-icon.open span:nth-child(1), .burger-icon.open span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.burger-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.burger-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.nav-mobile {
  z-index: 2000;
  background: $mobile-nav-background-color;
  width: 100%;
  height: calc(100vh - 72px);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  position: fixed;
  margin-top: 72px;
  > ul {
    list-style: none;
    padding: 0 16px;
    > li {
      display: flex;
      align-items: center;
      height: $spacer * 2;
      margin: 0;
      padding: 8px 16px;
      margin-bottom: 1px;
      position: relative;
      a {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        color: $mobile-nav-link-color !important;
        text-decoration: none !important;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        line-height: 1;
        cursor: pointer;
      }
      background: $header-button-background-color;
      &.with-caret {
        &:after {
          content: '';
          background: url('../img/arrow-down.svg?fill=#{$mobile-nav-link-color}') no-repeat center;
          width: $spacer / 2;
          height: $spacer / 2;
          position: absolute;
          right: $spacer / 2;
          transition: .2s linear;
        }
      }
      &.active {
        a {
          color: $mobile-nav-active-link-color !important;
        }
        background-color: $mobile-nav-active-background-color;
        &.with-caret {
          &::after {
            background: url('../img/arrow-down.svg?fill=#{$mobile-nav-active-link-color}') no-repeat center;
            transform: rotate(180deg);
          }
        }
      }
      &.submenu-link {
        height: 42px;
        margin-top: -1px;
        background-color: $mobile-nav-link-color;
        padding-left: 32px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: calc(100% - 32px);
          height: 1px;
          left: 0;
          top: 0;
          border-bottom: 1px solid rgba(0,0,0, 0.1);
          margin: 0 16px;
        }
        a {
          text-transform: none;
          font-weight: 400;
          font-size: 16px;
          color: $mobile-nav-active-link-color !important;
        }
        &.active {
          &::before {
            content: '';
            width: 2px;
            height: 100%;
            position: absolute;
            left: 16px;
            border-left: 2px solid $bottom-nav-active-border;
          }
          a {
            font-weight: 600;
          }
        }
      }
    }
    .submenu-spacer {
      height: $spacer;
      background-color: $mobile-nav-link-color;
      margin-top: -1px;
      &::before {
          content: '';
          position: absolute;
          width: calc(100% - 32px);
          height: 1px;
          left: 0;
          top: 0;
          border-bottom: 1px solid rgba(0,0,0, 0.1);
          margin: 0 16px;
      }
    }
  }
  .mobile-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
    .flex-0 {
      flex: 1 1 0%;
      min-width: calc(50% - 16px / 2);
    }
  }
}