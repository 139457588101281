.extras-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer / 2;
  margin-top: $spacer / 2;
  @include media-breakpoint-down(sm) {
    align-items: flex-start;
  }
  img {
    max-width: 100%;
    @include media-breakpoint-down(xs) {
      max-width: 250px;
    }
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: $spacer / 2;
    font-size: 19px;
    @include media-breakpoint-down(sm) {
      font-size: 17px;
    }
  }
}