.modal {
  .modal-content {
    border-radius: 0;
    .modal-header {
      border-radius: 0;
      border: none;
      background-color: $primary-color;
      color: $primary-button-text-color;
      font-family: $font-family-headings;
      margin-top: -1px;
      .close {
        opacity: 1;
        border: 1px solid $primary-button-text-color;
        padding: $spacer / 4;
        margin: 0 0 0 auto;
        width: 40px;
        height: 40px;
        font-size: inherit;
        &::before {
          background: url('../img/icon-close.svg?fill=#{$primary-button-text-color}') no-repeat center;
          background-size: contain;
          margin-bottom: -1px;
          margin-right: 0;
          width: 14px;
          height: 14px;
        }
        &:hover {
          border: 1px solid $button-light-hover-color;
          &::before {
            background: url('../img/icon-close.svg?fill=#{$button-light-hover-color}') no-repeat center;
            background-size: contain;
          }
        }
      }
    }
    .modal-body {
      padding: $spacer;
    }
  }
}
@include media-breakpoint-down(lg) {
  .modal-dialog {
    &.modal-lg {
      max-width: 800px;
    }
  }
}