.tnc-files-container {
  background: $light-gray;
  padding: $spacer;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.react-pdf__Page:not(:last-child) {
  border-bottom: 1px solid $light-gray;
}
.react-pdf__Page__canvas {
  margin-left: auto;
  margin-right: auto;
}