.promo-block-container {
  margin-top: -24px;
  margin-bottom: 24px;
  .no-style {
    text-transform: none;
    text-decoration: none;
  }
  .promo-block {
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid white;
    transition: 0.2s linear;
    &:hover {
      border: 1px solid $primary-color;
    }
    .promo-block-label {
      background-color: $secondary-color;
      display: inline-block;
      margin-left: 12px;
      margin-top: 12px;
      padding: 12px 16px;
      padding-bottom: 10px;
      color: white;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
    }
    .promo-block-text {
      background: rgba($primary-color, 0.8);
      color: white;
      min-height: 100px;
      padding: 18px;
      font-size: 18px;
      width: 100%;
      .promo-block-title {
        font-weight: bold;
      }
    }
    @include media-breakpoint-down(sm) {
      min-height: 240px;
      margin-bottom: 6px;
    }
  }
}