.table-striped {
  td {
    border-top: none;
  }
  &.table-lg {
    td {
      font-size: 18px;
    }
  }
}

.tables-container {
  padding-bottom: 1px;
  margin-bottom: 64px;
  overflow: auto;
}
@include media-breakpoint-down(sm) {
  .tables-container {
    margin-bottom: 32px;
    // margin-left: 16px;
    section {
      // margin-right: -16px;
      // margin-left: -16px;
    }
  }
}