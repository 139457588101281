$transition: 0.2s ease-out;
.faq-container {
  padding-top: 30px;
  padding-bottom: 30px;
  h2 {
    color: $primary-color !important;
  }
  .accordion {
    width: 100%;
    .accordion-item {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding: 3px 16px;
      @include media-breakpoint-down(md) {
        border-top: 1px solid $light-gray;
      }      
    }
    button {
      order: 1;
      white-space: normal;
      text-align: left;
      padding: 0;
      padding-left: 32px;
      position: relative;
      color: $light-black;
      text-decoration: none;
      font-size: 21px;
      text-transform: none;
      transition: $transition;
      width: 100%;
      &::before {
        content: '';
        background: url('../../img/icon-plus.svg?fill=#{$plus-icon-color}') no-repeat center;
        width: 16px;
        height: 16px;
        margin-right: 20px;
        display: inline-block; 
        position: absolute;
        left: 0;
        top: 8px;
      }
      p {
        margin-top: 2px !important;
      }
      @include media-breakpoint-down(md) {
        &::before {
          top: 6px;
        }
      }
      @include media-breakpoint-down(md) {
        padding-top: 6px;
        padding-bottom: 6px;
        margin-top: 2px !important;
        &::before {
          top: 12px;
        }        
      }      
    }
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $light-gray;
      z-index: -1;
      visibility: hidden;
      transition: $transition;
    }
    .collapse, .collapsing {
      order: 2;
      padding-left: 36px;
      p {
        padding-top: 12px;
        padding-bottom: 24px;
        font-size: 19px;
        margin-bottom: 0 !important;
      }
      @include media-breakpoint-down(md) {
        p {
          font-size: 15px;
        }
      }
      @include media-breakpoint-down(md) {
        p {
          padding-bottom: 16px;
          padding-top: 0;
        }
      }      
    }
    .collapse.show, .collapsing {
      ~ button {
        margin-top: 13px;
        p {
          font-weight: 600;
          color: #000;
        }
        &::before {
          content: '';
          background: url('../../img/icon-minus.svg?fill=#{$primary-color}') no-repeat center;
        }
      }
      ~ .background {
        visibility: visible;
      }
    }
    .accordion-item.closing {
      .background {
        visibility: hidden;
      }
      button {
        margin-top: 0;
        p {
          font-weight: normal;
        }
        &::before {
          content: '';
          background: url('../../img/icon-plus.svg?fill=#{$plus-icon-color}') no-repeat center;
        }
      }
    }
    .accordion-item:not(.closing) {
      margin-bottom: 18px;
      margin-top: 18px;
    }
    @include media-breakpoint-down(md) {
      .accordion-item:not(.closing) {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}