
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-Regular.otf') format('otf');
  font-display: swap;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../fonts/AvenirNextLTPro-Demi.otf') format('otf');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'Artegra Sans';
  src: url('../fonts/Artegra Sans Alt Regular.otf') format('otf');
  // font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Artegra Sans';
  src: url('../fonts/Artegra Sans Alt Bold.otf') format('otf');
  font-weight: 500 bold;
  font-display: swap;
}
@font-face {
  font-family: 'Avant Garde Gothic';
  src: url('../fonts/ITC\ Avant\ Garde\ Gothic\ Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: lighter;
  src: url('../fonts/webfonts/HelveticaNeueLight.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/webfonts/HelveticaNeueLight.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/webfonts/HelveticaNeueMedium.woff') format('woff');
  font-display: swap;
}
h1 {
  font-size: 36px;
  font-family: $font-family-headings;
  color: $primary-color;
  margin-bottom: 24px;
}

h2 {
  font-size: 28px;
  font-family: $font-family-headings;
  color: $secondary-color;
  margin-bottom: 24px;
}
.homepage {
  h2 {
    color: $primary-color;
  }
}

h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #000;
  font-family: $font-family-headings;
}
h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  font-family: $font-family-headings;
}
td {
  h3, h4 {
    margin-bottom: 0;
    line-height: 1;
    font-family: $font-family-main !important;
    text-transform: none;
    font-weight: 600 !important;
  }
  h3 {
    color: $secondary-color;
    font-size: 21px;
  }
}
h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 16px;
}

p {
  color: $light-black;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 1.68;
  min-height: 21px;
  & ~ h2 {
    margin-top: 64px;
  }
  &:last-child {
    margin-bottom: 64px;
  }
  &.smaller {
    font-size: 19px;
  }
}
b, strong {
  font-weight: 600;
}

a {
  color: $link-color;
}
.text-muted {
  color: $normal-gray !important;
  font-family: $font-family-main;
}
.text-black {
  color: $light-black;
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 17px;
  }
  p {
    font-size: 17px;
    line-height: 1.58;
    margin-bottom: 24px;
    min-height: 17px;
    &:last-child {
      margin-bottom: 48px;
    }
    &.smaller {
      font-size: 15px;
    }
  }
}
@include media-breakpoint-down(sm) {
  p {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 32px;
    }
  }
}