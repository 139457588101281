@import './assets/fonts/MyFontsWebfontsKit.css';
@import './assets/scss/style.scss';
body {
  margin: 0;
  padding: 0;
}

.wio-link {
  position: fixed;
  bottom: 12px;
  right: 12px;
  background: rgba(55, 94, 131, 0.4);
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  z-index: 1000;
  border-radius: 50%;
  &:hover {
    box-shadow: 0 0 4px 1px rgba(170, 214, 255, 0.4);
  }
}