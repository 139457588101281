.form-group {
  label {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: $spacer / 6;
    line-height: 1;
    &[required] {
      &::after {
        content: '*';
        color: #F00000;
        margin-left: 6px;
      }
    }
  }
  .form-control {
    border: 1px solid $medium-gray;
    border-radius: 0;
    height: 2 * $spacer;
    font-size: 17px;
    color: $light-black;
    &:focus {
      outline: none;
      border-color: $primary-color;
      box-shadow: none;
    }
    &.is-invalid {
      border: 1px solid $danger-color;
      background-image: none;
    }
  }
  select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../img/arrow-down.svg') no-repeat right 10px center, white;
    &::-ms-expand {
      display: none;
    }
    &.min-w {
      min-width: $spacer * 8;
    }
  }
}
.form-check {
  margin-bottom: $spacer / 4;
  .form-check-input {
    position: absolute;
    visibility: hidden;
    &:checked + .form-check-label {
      &::before {
        background: white;
        border: 1px solid $medium-gray;
      }
      &::after {
        position: absolute;
        left: 3px;
        top: 7px;
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background: $primary-color;
        border-radius: 50%;
      }
    }
  }
  .form-check-label {
    padding-left: 6px;
    &::before {
      position: absolute;
      left: 0;
      top: 4px;
      display: inline-block;
      content: '';
      width: 16px;
      height: 16px;
      border: 2px solid $primary-color;
      border-radius: 50%;
    }    
  }
}

// date input
.DateInput_input {
  width: 100%;
  border: 1px solid $medium-gray !important;
  height: 48px !important;
  font-family: $font-family-main !important;
  font-size: 17px !important;
  color: $light-black !important;
  font-weight: 400 !important;
}
.DateInput_input__focused {
  border-bottom: none !important;
  border: 1px solid $primary-color !important;
}