.pricing-table-container {
  .col-12 {
    max-width: none;
    width: auto;
    padding-right: 16px;
  }
  table {
    margin-bottom: -1px;
    border: 1px solid $medium-gray;
    table-layout: fixed;
    min-width: 600px;
    thead {
      tr td {
        padding: $spacer $spacer / 2;
      }
      &.border-bottom {
        tr td {
          border-bottom: 1px solid $medium-gray;
        }
      }
    }
    td {
      border-left: 1px solid $medium-gray;
      .icon {
        &::before {
          margin-right: 0;
        }
      }
      &.highlight {
        color: $success-color;
        font-weight: 600;
      }   
    }
    .header td {
      font-weight: 600;
    }
  }
  &.mb-5 {
    table {
      margin-bottom: 0;
    }
  }
}