.categories-container {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 48px;
  .category-link {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 15px;
    cursor: pointer;
    &.active, &:hover {
      color: $primary-color;
    }
  }
}
.categories-dropdown {
  @include media-breakpoint-down(sm) {
    max-width: 476px;
    margin-left: auto;
    margin-right: auto;
  }
}
.blog-posts-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: -12px;
  .blog-post-card {
    padding: 12px;
    max-width: 408px;
    padding-bottom: 36px;
    .blog-post-card-image {
      text-align: center;
      img {
        width: 100%;
      }
    }
    .blog-post-card-title {
      margin-top: 24px;
      text-align: center;
      font-size: 21px;
      @include media-breakpoint-down(md) {
        font-size: 17px;
      }
    }
    @include media-breakpoint-down(sm) {
      max-width: 500px;
      width: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.blog-paginator {
  display: flex;
  justify-content: center;
  button {
    height: 48px;
    width: 48px;
    margin: 2px;
    border: 1px solid $medium-gray;
    font-size: 19px;
    &.next-btn, &.prev-btn {
      width: 96px;
      min-width: 48px;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: url('../img/arrow-down.svg?fill=#{$primary-color}') no-repeat center;
      }
      &:disabled {
        &::before {
          background: url('../img/arrow-down.svg?fill=#{$medium-gray}') no-repeat center;
        }
        &:hover {
          border: 1px solid $medium-gray;
        }
      }
      &:hover:not(:disabled) {
        &::before {
          background: url('../img/arrow-down.svg?fill=#{$primary-button-text-color}') no-repeat center;
        }
      }      
    }
    &.next-btn {
      margin-left: 12px;
      &::before {
        transform: rotate(-90deg);
      }
    }
    &.prev-btn {
      margin-right: 12px;
      &::before {
        transform: rotate(90deg);
      }    
    }    
  }
}