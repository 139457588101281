@import "~bootstrap/scss/functions";
@import "variables";
@import "bootstrap_variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";



@import "~bootstrap/scss/bootstrap";
@import "typography";
@import "header";
@import "footer";
@import "buttons";
@import "dropdowns";
@import "lists";
@import "icons";
@import "pages";
@import "tables";
@import "modals";
@import "cc";
@import "contactform";
@import "loader";
@import "forms";
@import "blog";

@import "./slices/hero";
@import "./slices/columns";
@import "./slices/reviews";
@import "./slices/carinfo";
@import "./slices/faqblock";
@import "./slices/pricingtable";
@import "./slices/termsnconditions";
@import "./slices/promooffersblock";
@import "./slices/extraslist";


html {
  font-family: $font-family-main;
}
body {
  background: #fff;
  font-family: $font-family-main !important;
  font-size: 17px;
  margin: 0 !important;
  padding: 0 !important;
}

main {
  padding-top: 80px;
  padding-bottom: 60px;
  flex: 1 1 auto;
  max-width: 100%;
  height: 100%;
}
@include media-breakpoint-down(md) {
  main {
    padding-top: 48px;
  }
}
@include media-breakpoint-down(sm) {
  main {
    padding-top: 32px;
  }
}

p.block-img {
  img {
    max-width: 100%;
    width: 100%;
  }
  span {
    margin-top: 12px;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 17px;
    color: $normal-gray;
  }
  @include media-breakpoint-down(sm) {
    span {
      margin-top: 6px;
      font-size: 14px;
    }
  }
}

a[role="button"]:not(.cc-*-btn) {
  cursor: pointer;
  color: $link-color;
  &:hover {
    text-decoration: underline;
    color: $link-hover-color;
  }
}

.content {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
  margin-bottom: 48px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-responsive {
  width: 100%;
}

.pt-24 {
  padding-top: 24px;
}
.pt-48 {
  padding-top: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-48 {
  margin-bottom: 48px;
}
.px-48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.whitespace-normal {
  white-space: normal;
}
:root {
  --rc-booking-widget-primary-color: #{$primary-color} !important;
}

@include media-breakpoint-up(md) {
  .col-md-6 {
    &:nth-child(even) {
      padding-left: $spacer;
    }
  }
}

.h-100vh {
  min-height: 100vh;
}
.container {
  padding-left: $spacer;
  padding-right: $spacer;
}
@include media-breakpoint-down(sm) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.background-primary {
  background-color: $primary-color;
  color: $primary-button-text-color;
  p, button {
    color: $primary-button-text-color;
  }
  button:hover {
    color: $primary-button-text-color;
  }
}