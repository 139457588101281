.icon {
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 12px;
  }
  &.icon-phone {
    &::before {
      background: url('../img/icon-phone.svg?fill=#{$footer-link-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-email {
    &::before {
      background: url('../img/icon-email.svg?fill=#{$footer-link-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-star {
    &::before {
      background: url('../img/icon-star.svg?fill=#{$rating-color}') no-repeat center;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }
  &.icon-star-yellow {
    &::before {
      background: url('../img/icon-star.svg?fill=#F8DD61') no-repeat center;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }    
  &.icon-star-empty {
    &::before {
      background: url('../img/icon-empty-star.svg') no-repeat center;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }  
  &.icon-questionmark {
    &::before {
      background: url('../img/icon-questionmark.svg') no-repeat center;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }
  &.icon-close {
    &::before {
      background: url('../img/icon-close.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }
  &.icon-checkbox {
    &::before {
      background: url('../img/icon-tick.svg?fill=#{$primary-color}') no-repeat center, white;
      border-radius: 50%;
      background-size: contain;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }
  &.icon-none {
    &::before {
      background: url('../img/icon-none.svg?fill=#{$medium-gray}') no-repeat center, white;
      background-size: contain;
      border-radius: 50%;
      margin-bottom: -2px;
      margin-right: 6px;
    }
  }
  &.icon-door {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-door.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-fuel {
    &::before {
      margin-right: 6px;
      background: url('../img/icon-fuel.svg?stroke=#{$primary-color}') no-repeat center;
      background-size: contain;      
    }
  }
  &.icon-gearbox {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-transmission.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-location {
    &::before {
      margin-right: 6px;
      background: url('../img/icon-location.svg?stroke=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-luggage {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-luggage.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-GPS {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-navigation.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }  
  &.icon-person {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-passenger.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-snowflake {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-conditioner.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-time {
    &::before {
      margin-right: 6px;
      background: url('../img/icon-time.svg?stroke=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-wheel {
    &::before {
      margin-right: 4px;
      background: url('../img/caricon-wheel.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-BLUETOOTH {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-bluetooth.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-AUX {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-aux.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-CRUISE {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-cruisecontrol.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-CARPLAY {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-hand.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-HEAT {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-heatedseats.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }
  &.icon-CAMERA {
    &::before {
      margin-right: 6px;
      background: url('../img/caricon-rearcamera.svg?fill=#{$primary-color}') no-repeat center;
      background-size: contain;
    }
  }    
  &.icon-lg {
    &::before {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      margin-bottom: -4px;
    }
  }
  &.icon-xl {
    &::before {
      width: 32px;
      height: 32px;
      margin-right: 12px;
      margin-bottom: -4px;
    }
  }  
}