ul, ol {
  padding-left: 0;
  li {
    font-size: 21px;
    font-weight: 400;
    line-height: 1.68;
  }
}
ol {
  padding-left: $spacer;
  li {
    padding-left: $spacer / 2;
    padding-bottom: $spacer / 2;
  }
}
@include media-breakpoint-down(md) {
  ul li, ol li {
    font-size: 17px;
  }
}
ul:not(.nav-links):not(.nav-mobile-ul):not(.DayPicker_weekHeader_ul) {
  list-style: none;
  padding-left: 24px;
  li:not(.DayPicker_weekHeader_li) {
    position: relative;
    padding-left: $spacer;
    &::before {
      position: absolute;
      left: 0;
      content: '';
      background: $info-color;
      background-size: contain;
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      display: inline-block;
      border-radius: 50%;
      margin-top: 12px;
    }
    margin-bottom: 6px;
    &.with-arrow {
      &::before {
        content: '';
        background: url('../img/icon-arrow-right.svg?fill=#{$plus-icon-color}') no-repeat
          center;
        background-size: contain;
        width: 19px;
        min-width: 19px;
        height: 17px;
        min-height: 17px;
        display: inline-block;
        margin-top: 6px;
        left: -3px;
      }
    }
    @include media-breakpoint-down(md) {
      &::before {
        margin-top: 10px;
      }
      &.with-arrow::before {
        margin-top: 4px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    padding-left: 16px;
  }
}
.list-arrow {
  ul {
    list-style: none;
    padding-left: 0 !important;
    li:not(.DayPicker_weekHeader_li) {
      &::before {
        content: '';
        background: url('../img/icon-arrow-right.svg?fill=#{$plus-icon-color}') no-repeat
          center !important;
        background-size: contain !important;
        width: 19px !important;
        min-width: 19px !important;
        height: 17px !important;
        min-height: 17px !important;
        display: inline-block !important;
        margin-top: 6px !important;
        left: 0 !important;
      }
      margin-bottom: 6px;
    }
  }
}
.list-normal {
  ul {
    list-style: none;
    padding-left: 0 !important;
    li {
      padding-left: 30px !important;
      &::before {
        content: '';
        background: url('../img/icon-plus.svg?fill=#{$plus-icon-color}') no-repeat
          center !important;
        background-size: contain !important;
        width: 18px !important;
        min-width: 18px !important;
        height: 18px !important;
        min-height: 18px !important;
        display: inline-block !important;
        margin-top: 8px !important;
        left: 0 !important;
      }
      @include media-breakpoint-down(md) {
        &::before {
          margin-top: 5px !important;
        }
      }
      margin-bottom: 6px;
    }    
  }
}
.list-checkbox {
  ul {
    padding-left: 0 !important;
    list-style: none;
    li {
      padding-left: 36px !important;
      &::before {
        content: '';
        background: url('../img/icon-tick.svg') no-repeat
          center, white !important;
        background-size: contain !important;
        width: 24px !important;
        min-width: 24px !important;
        height: 24px !important;
        min-height: 24px !important;
        display: inline-block !important;
        border-radius: 50% !important;
        margin-top: 5px !important;
        left: 0 !important;
      }
      @include media-breakpoint-down(md) {
        &::before {
          margin-top: 2px !important;
        }
      }
      margin-bottom: 6px;
    }    
  }
}
