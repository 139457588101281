.dropdown-toggle {
  padding-right: 25px !important;
  text-transform: uppercase !important;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    background: url('../img/arrow-down.svg?fill=#{$top-nav-active-border}') no-repeat center;
    width: 12px;
    height: 12px;
    border: none !important;
    transition: .2s linear;
  }
  &::after {
    border: none !important;
  }
  &.btn-light {
    &::before {
      background: url('../img/arrow-down.svg?fill=#000') no-repeat center;
    }
  }
  &.btn-link {
    &:hover {
      color: $top-nav-active-border;
      text-decoration: none;
    }
  }
}
.show {
  .dropdown-toggle::before {
    transform: rotate(180deg) translateY(50%);
  }
  .dropdown-toggle::after {
    border: none !important;
  }
}


.dropdown-menu {
  // padding: 0;
  border-radius: 0;
  border: none;
  font-size: 17px;
  min-width: 90px !important;
  text-align: left;
  width: 100%;
  box-shadow: $box-shadow;
  .dropdown-item {
    padding: 12px;
    &.active {
      font-weight: 600;
      color: $light-black;
      background-color: white;
      pointer-events: none;
    }
    &:hover {
      background-color: $light-gray;
    }
  }
}