$light-black: #2E3133;
$light-gray: #F2F4F7;
$medium-gray: #D2D4D6;
$normal-gray: #828385;
$dark-gray: #4E555B;
$primary-color: #044785;
$secondary-color: #D81E26;
$success-color: #3EAE3E;
$danger-color: #F00000;
$info-color: $primary-color;
$button-light-color: white;
$button-light-hover-color: white;
$book-car-cta-button-color: #D81E26;
$book-car-cta-button-text-color: white;

$primary-button-text-color: white;
$link-color: #005EB8;
$header-button-background-color: #1D5A93;
$header-border-bottom: $light-gray;
$top-nav-background-color: white;
$top-nav-open-background-color: $primary-color;
$top-nav-active-border: $secondary-color;
$top-nav-link-color: $primary-color;
$top-nav-outline-button-outline: $primary-color;
$top-nav-outline-button-outline-hover: $primary-color;
$top-nav-outline-button-color: $primary-color;
$top-nav-outline-button-color-hover: $primary-color;

$bottom-nav-background-color: $primary-color;
$bottom-nav-active-border: #D81E26;
$bottom-nav-link-color: white;
$bottom-nav-link-hover-color: $light-gray;

$nav-burger-color: $primary-color;
$nav-burger-background: white;
$nav-burger-border: 1px solid $light-gray;
$nav-burger-open-color: white;
$nav-burger-open-background: #1D5A93;

$mobile-nav-background-color: $top-nav-open-background-color;
$mobile-nav-link-color: white;
$mobile-nav-active-background-color: $mobile-nav-link-color;
$mobile-nav-active-link-color: $top-nav-link-color;

$font-family-main: 'Avenir Next', sans-serif;
$font-family-headings: 'Avant Garde Gothic', sans-serif;

$footer-bottom-section-background-color: white;
$footer-bottom-section-text-color: $dark-gray;
$footer-background-color: $primary-color;
$footer-text-color: white;
$footer-link-color: $secondary-color;

$box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);

$rating-background: $secondary-color;
$rating-color: white;

$columns-with-background: $primary-color;

$plus-icon-color: $secondary-color;

// override styles
footer .top-section {
  a {
    color: white !important;
    text-decoration: underline;
  }
}

.columns-with-background {
  color: white !important;
  h2, h3, a, p {
    color: white !important;
  }
}
.contact-form {
  h2 {
    color: $primary-color !important;
  }
}
h1, h2, h3, h4 {
  font-weight: bold !important;
}
h2 {
  color: black !important;
}