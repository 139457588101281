.review {
  padding: 48px;
  color: $light-black;
  background-color: #F8F9FB;
  max-width: 80%;
  margin-top: 48px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  .bottom-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 6px 0;
    font-size: 21px;
    .rating {
      background-color: $rating-background;
      border-radius: 24px;
      padding: 8px 16px;
      color: $rating-color;
      font-weight: 700;
      margin-right: 24px;
      position: relative;
      line-height: 1;
      font-size: 16px;
    }
    .reviewer-container {
      display: flex;
      .reviewer {
        font-weight: 700;
        margin-right: 24px;
        color: $light-black;
      }
      .date {
        color: $dark-gray;
      }
    }

  }
  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(odd) {
    margin-left: auto;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 16px;
  }
}
.horizontal-review {
  padding: $spacer / 2;
  flex: 1 1 25%;
  display: flex;
  align-items: center;  
  flex-direction: column;
  .rating-image {
    // max-height: 150px;
    img {
      max-width: 100%;
      max-height: 160px;
    }
  }  
  .rating {
    text-align: center;
    .rating-value {
      display: block;
      font-size: 43px;
      font-family: $font-family-headings;
      color: $danger-color;
    }
    ~ .rating-image {
      margin-top: $spacer / 2;
      max-width: 220px;
      img {
        max-height: 60px;
      }
    }
  }
  .bottom-row {
    margin-top: auto;
    text-align: center;
  }
}
@include media-breakpoint-down(md) {
  .review {
    padding: 24px;
    max-width: 100%;
    margin-top: 16px;
    .bottom-row {
      font-size: 17px;
    }
    &:nth-child(2) {
      margin-top: 0 !important;
    }
    &:nth-child(odd) {
      margin-left: 0;
    }
  }
  .horizontal-review {
    flex: 1 1 auto;
  }
}
@include media-breakpoint-down(sm) {
  .review {
    padding: 16px;
    margin-left: 0;
    margin-right: 0;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    p {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .bottom-row {
      .rating {
        margin-right: 0;
        margin-bottom: 8px;
        font-size: 14px;
      }
      .reviewer-container {
        flex: 100%;
        font-size: 14px;
        .reviewer {
          margin-right: 8px;
        }
      }
    }
    &:nth-child(odd) {
      margin-left: 0;
      border-bottom-left-radius: 8px;
      .bottom-row {
        justify-content: flex-end;
      }
      .reviewer-container {
        justify-content: flex-end;
      }
    }    
  }
  .horizontal-review {
    .rating-image {
    // max-height: 150px;
      img {
        max-height: 120px;
      }
    }
    .rating {
      text-align: center;
      .rating-value {
        font-size: 38px;
      }
      ~ .rating-image {
        margin-top: $spacer / 2;
        max-width: 190px;
        img {
          max-height: 40px;
        }
      }
    }
    .bottom-row {
      margin-top: $spacer / 2;
    } 
  }
}