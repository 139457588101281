.car-info-card-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: - $spacer;
  padding-right: 12px;
  width: 100%;
  .car-info-card-border {
    padding: $spacer / 2 $spacer / 2;
    max-width: 282px;
    min-width: 282px;
    min-height: 320px;
    max-height: 350px;
    flex: 1 1 25%;
    a {
      color: $light-black;
      display: flex;
      height: 100%;
      &:hover {
        text-decoration: none;
      }
    }
    &.with-btn {
      max-height: 380px;
      a {
        height: auto;
      }
    }
  }
  .border-container {
    &:hover {
      .car-info-card-book-btn {
        border: 1px solid $primary-color !important;
        border-top: 1px solid #ececec !important;
      }
      .car-info-card {
        border: 1px solid $primary-color;
      }
    }
  }  
  .car-info-card {
    padding: $spacer / 2 $spacer;
    overflow: hidden;
    border: 1px solid $medium-gray;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: .2s linear;
    min-height: 309px;
    .car-info-card-header {
      text-align: center;
      padding-bottom: $spacer / 2;
      .car-model {
        display: block;
        font-weight: 600;
        color: $primary-color;
      }
      .car-category {
        color: $light-black;
      }
    }
    .car-info-card-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
    .car-info-card-body {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex: 1 1 auto;
      margin-left: -8px;
      margin-right: -8px;
      img {
        max-width: 100%;
        width: auto;
        max-height: 160px;
        margin: auto;
      }
    }
    .car-info-card-vehicledata {
      border-top: 1px solid #ececec;
      height: 48px;
      min-height: 48px;
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: -12px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 15px;
      span {
        padding-left: 6px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        line-height: 1;
        margin-top: 3px;
        &::before {
          margin-top: -3px;
        }
      }
    }
  }  
  &.promo-page {
    .car-info-card-border {
      flex: 1 1 33%;
      max-width: 384px;
      max-height: 492px;
      .car-info-card {
        width: 100%;
        padding-top: 24px;
        border-bottom: 1px solid transparent;
        .car-info-card-vehicledata {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
    .car-info-card-book-btn {
      padding: 18px 24px;
      border: 1px solid $medium-gray;
      margin-top: -1px;
      z-index: 1;
      border-top: 1px solid #ececec;
      transition: 0.2s linear;
    }    
  }
}
@include media-breakpoint-down(sm) {
  .car-info-card-container {
    margin-right: 0;
    margin-left: 12px;
    .car-info-card-border {
      width: 100% !important;
      max-width: 100% !important;
      padding: 0;
      flex: 1 1 100% !important;
      max-height: 140px;
      min-height: 130px;
      &:first-child {
        border-top: 1px solid #ececec;
      }
      .car-info-card {
        flex-direction: row;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: 1px solid #ececec;
        padding: 16px;
        min-height: 100px;
        width: 100%;
        &:hover {
          margin-top: 0;
          border-bottom: 1px solid $primary-color;
        }
        .car-info-card-header {
          text-align: left;
          font-size: 14px;
          flex: 1 1 auto;
          .car-model {
            font-size: 16px;
            line-height: 1.2;
          }
        }
        .car-info-card-body {
          width: 40%;
          max-width: 40%;
          margin-right: 0;
          img {
            max-height: 110px;
            width: auto;
            max-width: 100%;
          }
        }
        .car-info-card-content {
          width: 60%;
          padding-left: 16px;
          min-width: 184px;
          .car-info-card-vehicledata {
            border-top: none;
            margin-left: 0;
            margin-right: -16px;
            justify-content: flex-start;
            flex-wrap: wrap;
            span {
              padding-right: 6px;
              padding-left: 0;
              font-size: 14px;
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
      .car-info-card-book-btn {
        border: 1px solid transparent !important;
        &:hover {
          border: 1px solid transparent !important;
        }
      }
      &:hover {
        .car-info-card-book-btn {
          border-bottom: 1px solid $primary-color !important;
        }
      }
    }
    &.promo-page {
      .car-info-card:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}
