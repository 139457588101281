.btn {
  white-space: nowrap;
  border-radius: 0 !important;
  text-transform: uppercase;
  font-family: $font-family-main;
  font-size: 14px;
  line-height: 14px;
  // padding: 10px 20px !important;
  &.btn-lg {
    font-size: 14px;
    font-weight: 600;
    padding: 16px $spacer;
    height: $spacer * 2;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  &.btn-xl {
    height: 64px;
    font-weight: 600;
    font-size: 18px;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    line-height: 1.2;
  }
  &.btn-light {
    // background-color: $button-light-color;
    &:hover {
      background-color: $button-light-hover-color;
    }
  }
  &.btn-outline-light {
    border: 1px solid rgba($button-light-color, 0.5);
    &:hover {
      background-color: transparent;
      border: 1px solid $button-light-hover-color;
      color: $button-light-hover-color;
    }
  }
  &.btn-link {
    text-transform: none;
    line-height: 1;
  }
  &.btn-warning {
    color: $book-car-cta-button-text-color;
  }
  &.btn-link-arrow {
    border: 1px solid $medium-gray;
    &::before {
      content: '';
      background: url('../img/icon-arrow-right.svg?fill=white') no-repeat center, red;
      background-size: 60%;
      width: 24px;
      height: 24px;
      margin-right: 10px;
      margin-top: -2px;
      border-radius: 50%;
    }
  }
}

