footer {
  background-color: $footer-background-color;
  margin-top: $spacer;
  letter-spacing: 0.5px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  p {
    font-size: 16px;
    margin-bottom: $spacer;
  }
  span {
    margin-bottom: 6px;
  }
  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $spacer * 2;
    font-size: 14px;
    background-color: $footer-bottom-section-background-color;
    padding: $spacer;
    text-align: center;
    color: $footer-bottom-section-text-color;
    p {
      margin: 0;
      font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
      padding: 16px;
    }
  }
  .top-section {
    padding: $spacer * 2 $spacer;
    color: $footer-text-color !important;
    flex: 1 1 auto;
    h5 {
      text-transform: uppercase;
    }
    p {
      color: $footer-text-color !important;
    }
    ul {
      list-style: none;
      margin-bottom: 0 !important;
      padding-left: 0 !important;
      li {
        &::before {
          content: '';
          background: url('../img/icon-arrow-right.svg?fill=#{$footer-link-color}') no-repeat center !important;
          background-size: contain;
          width: 14px !important;
          min-width: 14px !important;
          height: 13px !important;
          min-height: 13px !important;
          display: inline-block;
          margin-top: 6px !important;
          margin-right: $spacer / 2 !important;
          border-radius: 0 !important;
        }
        font-size: 16px;
        a {
          color: $footer-text-color;
        }
      }
    }
    a {
      color: $footer-link-color;
    }
    @include media-breakpoint-down(sm) {
      padding: 32px 16px;
      .col-12:not(:last-child) {
        padding-bottom: $spacer;
      }
    }
  }
  .social-media-bar-background {
    background-color: #F8F9FB;
    .social-media-bar {
      padding: $spacer / 2 $spacer;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }
    }
    .association-col {
      display: flex;
      align-items: center;
      max-width: 400px;
      padding: 12px 0;
      img {
        height: 64px;
        ~ span {
          margin-left: $spacer;
        }
      }
      span {
        margin-bottom: 0;
        color: $normal-gray;
      }
    }
    .social-media-col {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 12px 0;
      .social-media-item {
        display: flex;
        padding: 6px 12px;
        align-items: center;
        img {
          margin-right: 12px;
        }
      }
    }
  }
  
}