$grid-breakpoints: (
  xs: 0,
  sm: 650px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1420px
) !default;

$container-max-widths: (
  sm: 768px,
  md: 1024px,
  lg: 1200px,
  xl: 1248px
) !default;

$primary:       $primary-color !default;
$secondary:     $secondary-color !default;
$success:       $success-color !default;
$info:          $info-color !default;
// $danger:        $red !default;
$light:         $button-light-color !default;
$warning:       $book-car-cta-button-color !default;
// $dark:          $gray-800 !default;

$grid-gutter-width: 24px;
$spacer: 24px;

$table-accent-bg: $light-gray !default;
