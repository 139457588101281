.contact-form {
  background: $light-gray;
  padding: $spacer * 2;
  h2 {
    color: $primary-color;
  }
}

/*--- FORM CONTAINER STYLE ---*/
.mainDiv
{
  margin:auto;
  float: none !important;
  padding: 0 !important;
}
 
.responsiveRow {
  margin-bottom: 18px;
}
.responsiveCell {
  margin-right: $spacer;
}
.responsiveCellSize1 {
  min-width: 384px !important;
  width: 384px !important;
}
.hideMe {
  display: none !important;
}

.align-top {
  padding-top: 10px;
}
 
.QapTcha {
  padding-top: 15px;
  width:384px !important;
}
 
/*--- FIELD INPUT STYLE ---*/
.clickdform input[type='text'],
.clickdform textarea,
.clickdform select
{
    font-size: 17px !important;
    color:#333333;
    margin:0;
    padding: 6px 12px;
    clear: both;
    border: 1px solid $medium-gray;
    border-radius: 0;
    height: 48px;
    min-height: 38px;
    min-width: 384px;
    &:focus {
      outline: none;
      border: solid $primary-color 1px;
    }
}
.clickdform input[type='checkbox'] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
  ~ span {
    display: inline-block;
    margin-top: -1px;
    vertical-align: top;
  }
}
.floatLeft {
  float: none !important;
}
.clickdform .minSize1 {
  min-width: 250px !important;
  width: 100% !important;
}
.maxSize1, .maxSize2 {
  font-family: $font-family-main !important;
  font-size: 17px !important;
}
span.maxSize1, span.maxSize2 {
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1;
  margin-bottom: 4px;
}
 
 
/*--- LIST DROPDOWN STYLE ---*/
.clickdform select
{
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('../img/arrow-down.svg') no-repeat right 10px center, white;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      outline: none;
      border-color: $primary-color;
      box-shadow: none;
    }
}
 
/*--- SUBMIT BUTTON STYLE ---*/
.buttonContainer {
  margin: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100%;
}
#btnSubmit {
  width: 100% !important;
    margin: 30px 0 0 0;
    font-size:20px !important;
    border-top:1px solid #ddd;
    border-left:1px solid #ddd;
    border-right:1px solid #ddd;
    border-bottom:1px solid #ddd;
    background-color: $info-color !important;
    color: $primary-button-text-color !important;
    text-transform: uppercase;
    font-size: 14px !important;
    font-weight: 600 !important;
    // color: white !important;
    font-family: $font-family-main !important;
    padding:5px;
    width: 160px;
    height: 50px;
    clear:both;
    &:hover {
      background-color: darken($info-color, 5%) !important;
    }
    &:focus {
      outline: none;
    }
}
 
/*--- PREVIOUS BUTTON STYLE ---*/
.clickdform .WizardPrevButton{
    margin: 15px 10px 0 0;               
    font-size:13px;
}
 
/*--- NEXT BUTTON STYLE ---*/
.clickdform .WizardNextButton
{
    margin: 15px 0px 0 0;
    font-size:13px;
    clear:both;
}
 
/*--- ANNATA CUSTOM STYLES ---*/
.hideMe{
    //visibility: hidden;
    display: none;
}
@include media-breakpoint-down(sm) {
  .alignTop {
    display: block !important;
  }
  .responsiveCellSize1, .responsiveCellSize2 {
    width: 100% !important;
    min-width: 280px !important;
  }
  .clickdform input[type='text'],
  .clickdform textarea,
  .clickdform select
  {
    min-width: 100% !important;
  }  
}