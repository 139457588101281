.hero-vertical {
  // position: relative;
  width: 100%;
  .img-container {
    height: 660px;
    overflow: hidden;
    .container {
      padding: $spacer * 3 $spacer;
      display: flex;
      flex-wrap: nowrap;
      height: 100%;
      &.narrow-padding {
        padding: $spacer * 1.5 $spacer;
      }
    }
  }
  .booking-widget-container {
    display: inline-block;
    width: 384px;
    min-width: 384px;
    min-height: 500px;
    background: white;
    padding: $spacer / 2;
  }
  .campaign-container {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-end;
    padding: 0;
    padding-left: $spacer;
    margin-bottom: - $spacer / 2;
  }
  @include media-breakpoint-down(sm) {
    .img-container {
      height: auto;
      background-color: $light-gray;
    }
    .img-container .container {
      flex-wrap: wrap;
      padding: 32px 16px;
    }
    .booking-widget-container {
      width: 100%;
      min-width: 280px;
    }
    .campaign-container {
      padding: 0;
    }
  }  
}

.campaign-card {
  width: 372px;
  min-width: 372px;
  height: 218px;
  padding: $spacer / 2;
  background: transparent;
  border: none !important;
  margin: 0;
  a {
    height: 100%;
    display: inherit;
    text-decoration: none !important;
  }
  .card-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    background-color: #EE2249;
    transition: 0.2s;
    .card-title {
      font-family: $font-family-headings;
      font-size: 28px;
      margin-bottom: 0;
      color: white;
    }
    .card-text {
      color: white;
      font-size: 21px;
      font-weight: 400;
    }
    &:hover {
      box-shadow: $box-shadow;
    }
  }
  &:nth-child(3n + 2) {
    .card-body {
      background-color: #AB206C;
    }
  }
  &:nth-child(3n + 3) {
    .card-body {
      background-color: #F36C3D;
    }
  }  
}
@include media-breakpoint-down(lg) {
  .campaign-card {
    width: 320px;
    min-width: 296px !important;
    height: 180px;
    .card-body {
      .card-title {
        font-size: 20px;
      }
      .card-text {
        font-size: 16px;
      }
    }

  }
}

@include media-breakpoint-down(sm) {
  .campaign-container {
    margin-top: 12px;
  }
  .campaign-card {
    width: 100%;
    margin-right: 0 !important;
    min-width: 300px !important;
    height: 184px;
    padding-left: 0;
    padding-right: 0;
  }
  .hero-vertical .booking-widget-container {
    padding: 4px;
  }
}

.promo-hero {
  width: 100%;
  height: 628px;
  margin-bottom: $spacer * 2;
  margin-top: $spacer * 2;
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: $spacer;
  .booking-widget-container {
    min-width: 384px;
    width: 384px;
    height: 100%;
    padding: $spacer / 2;
    background: white;
    margin-left: auto;
  }
  &.partner-page {
    height: 660px;
  }
  .promo-hero-text {
    font-size: 36px;
    margin-right: 24px;
    p {
      color: white;
      font-size: 36px;
      margin-bottom: 0;
      line-height: 1.25;
      strong {
        background: $danger-color;
        padding: 2px 8px;
        color: white;
      }
    }
    &.white {
      p {
        color: white;
      }
    }
    &.black {
      p {
        color: black;
      }
    }
  }
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    height: auto;
    .promo-hero-text {
      margin-bottom: 24px;
      p {
        font-size: 21px;
        line-height: 1.5;
        margin-right: 0;
      }
    }
    .booking-widget-container {
      width: 100%;
      min-width: 280px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-left: -16px;
    margin-right: -16px;
    width: auto;
    .promo-hero-text {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}