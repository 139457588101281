.columns-with-background {
  background-color: $columns-with-background;
}
.columns-container {
  padding-top: 24px;
  padding-bottom: 24px;
}
.homepage {
  .columns-container {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  @include media-breakpoint-down(sm) {
    .columns-container {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}